import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {PrivacyPoliciesComponent} from './views/privacy-policies/privacy-policies.component';
import {TermsOfUseComponent} from './views/terms-of-use/terms-of-use.component';

const routes: Routes = [
  {
    path: '', component: MainComponent, children: [
      {path: '', component: HomeComponent},
      {path: 'privacy-policies', component: PrivacyPoliciesComponent},
      {path: 'terms-of-use', component: TermsOfUseComponent},
      {path: 'delete-account', loadChildren: () => import('./views/delete-account/delete-account.module').then(m => m.DeleteAccountModule)},
    ]
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
